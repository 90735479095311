"use client";

import React from "react";
import { Modal } from "react-bootstrap";

type CustomModalProps = {
  children: React.ReactElement;
  showModal: boolean;
  showCloseIcon?: boolean;
  onDismiss: VoidFunction;
  size?: "sm" | "lg" | "xl" | "md";
  className?: string;
};

const CustomModal = ({
  children,
  onDismiss,
  showModal,
  showCloseIcon = true,
  size,
  className,
}: CustomModalProps) => {
  return (
    <Modal
      backdrop="static"
      show={showModal}
      centered
      size={size === "md" ? undefined : size}
      className={`${className} custom-modal-dialog`}
      backdropClassName="custom-modal-backdrop"
    >
      <div className="w-100 bg-white">
        <div
          className="progress-meter bg-primary"
          style={{ width: "100%" }}
        ></div>
      </div>
      <div className="">
        <div className="d-flex justify-content-between">
          {showCloseIcon && (
            <button type="button" className="close" onPointerDown={onDismiss}>
              <span aria-hidden="true">
                <svg
                  id="Icon_ionic-ios-close-circle-outline"
                  data-name="Icon ionic-ios-close-circle-outline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.097"
                  height="26.097"
                  viewBox="0 0 34.097 34.097"
                >
                  <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M25.107,23.255l-4.319-4.319,4.319-4.319a1.31,1.31,0,0,0-1.852-1.852l-4.319,4.319-4.319-4.319a1.31,1.31,0,1,0-1.852,1.852l4.319,4.319-4.319,4.319a1.266,1.266,0,0,0,0,1.852,1.3,1.3,0,0,0,1.852,0l4.319-4.319,4.319,4.319a1.316,1.316,0,0,0,1.852,0A1.3,1.3,0,0,0,25.107,23.255Z"
                    transform="translate(-1.886 -1.887)"
                  />
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M20.423,5.67A14.748,14.748,0,1,1,9.989,9.989,14.656,14.656,0,0,1,20.423,5.67m0-2.295A17.048,17.048,0,1,0,37.472,20.423,17.046,17.046,0,0,0,20.423,3.375Z"
                    transform="translate(-3.375 -3.375)"
                  />
                </svg>
              </span>
            </button>
          )}
        </div>
        <div className="modal-body ">
          <div className=" ">{children}</div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
