import Link from "next/link";

export const LinkToAnotherPage = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  return <Link href={href}>{children}</Link>;
};

export const LinkToAnotherPageWithClassName = ({
  href,
  children,
  className,
}: {
  href: string;
  children: React.ReactNode;
  className: string;
}) => {
  return (
    <Link href={href} className={className}>
      {children}
    </Link>
  );
};

export const LinkWithoutHref = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Link href="#">{children}</Link>;
};

export const LinkWithoutHrefWithClassName = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) => {
  return (
    <Link href="#" className={className}>
      {children}
    </Link>
  );
};

export const DropdownToggleButton = ({
  children,
  className,
  id,
  role,
  dataToggle,
  ariaHaspopup,
  ariaExpanded,
  href,
  onClick,
}: {
  children: React.ReactNode;
  className: string;
  id: string;
  role: string;
  dataToggle: string;
  ariaHaspopup: boolean;
  ariaExpanded: boolean;
  href?: string;
  onClick?: () => void;
}) => {
  return (
    <a
      className={className}
      id={id}
      role={role}
      data-toggle={dataToggle}
      aria-haspopup={ariaHaspopup}
      aria-expanded={ariaExpanded}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export const generateReactKey = (id?: number) => {
  return "rand" + (id ?? Math.ceil(Math.random() * 1000));
};
