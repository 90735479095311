export const BRAND_NAME: string = "Electricity Company of Ghana";

export const BRAND_TAGLINE: string = "ECG Backoffice";

export const BRAND_DESCRIPTION: string = "The name behind electricity in Ghana";

export const BRAND_URL: string = "https://ecg.gov-gh.com";

export const BRAND_IMAGE: string = "https://ecg.gov-gh.com/logo.png";

export const GOOGLE_MAP_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY!;

export const STAFF_PERFORMANCE_API: string =
  "https://api-staff-perf.gov-gh.com";

export const USER_ROLES = [
  { value: "administrator", name: "Administrator" },
  { value: "staff", name: "Staff" },
  { value: "manage-vendors", name: "Manage Vendors" },
  { value: "manage-wallets", name: "Manage Wallets" },
  { value: "manage-staff", name: "Manage Staff" },
  { value: "national-security", name: "National Security" },
  { value: "notifications", name: "Notifications" },
  { value: "qa", name: "QA" },
] as const;

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const adminUserFilters = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "email",
    label: "Email",
  },
  {
    value: "phone",
    label: "Phone Number",
  },
  {
    value: "IsActive",
    label: "Status",
  },
  {
    value: "Role",
    label: "Role",
  },
];

export const adminRolesFilters = [
  {
    value: "staff",
    label: "Staff",
  },
  {
    value: "administrator",
    label: "Administrator",
  },
  {
    value: "national-security",
    label: "National Security",
  },
  {
    value: "manage-wallets",
    label: "Manage Wallets",
  },
  {
    value: "manage-vendors",
    label: "Manage Vendors",
  },
  {
    value: "manage-staff",
    label: "Manage Staff",
  },
];

export const citizenList = [
  { value: "__all__", label: "__all__" },
  { value: "Citizen", label: "Citizen" },
  { value: "Non-Citizen", label: "Non-Citizen" },
];

export const userIssuesFilters = [
  { name: "General Enquiry" },
  {
    name: "I have no response to my earlier complaint",
    text: "Has Unresolved Past Complaints",
  },
  {
    name: "Fault / Outage",
  },
  {
    name: "Theft / Staff Misconduct",
  },
  {
    name: "I have not received my refund in my wallet",
    text: "Not Refunded",
  },
  {
    name: "Transaction",
    text: "Transactions",
  },
  {
    name: "Disconnection / New Service",
  },
];

export const meterTypes = [
  { label: "Electrocash 2", value: "electrocash 2" },
  { label: "Ecash", value: "Ecash" },
  { label: "MMS-Gamma", value: "MMS-Gamma" },
  { label: "I-CREDIT", value: "i-credit" },
  { label: "GEM ELECTRONIC", value: "gem electronic" },
  { label: "SML", value: "sml" },
  { label: "Non Energy", value: "NONENERGY" },
  { label: "SMART G", value: "smart g" },
  { label: "General Electric", value: "general electric" },
  { label: "Pay N Smile (PNS)", value: "pay n smile (pns)" },
  { label: "AEG", value: "aeg" },
  { label: "IM", value: "im" },
  { label: "HUYU", value: "huyu" },
  { label: "INTECH", value: "intech" },
  { label: "ELYMER", value: "elymer" },
  { label: "BXC", value: "bxc" },
  { label: "PNS-2", value: "pns-2" },
  { label: "SMART CASH (BXC)", value: "smart cash (bxc)" },
  { label: "E-CASH 2", value: "e-cash 2" },
  { label: "GAMA", value: "gama" },
  { label: "PNS SMART", value: "pns smart" },
  { label: "CLOU NEW", value: "clou new" },
  { label: "NET", value: "net" },
  { label: "CNCC", value: "cncc" },
  { label: "Street Light Make", value: "street light make" },
  { label: "MMS-MBH", value: "mms-mbh" },
  { label: "Aimir", value: "aimir" },
  { label: "E-CASH4", value: "e-cash4" },
  { label: "Electrocash 1 and 3", value: "electrocash 1 and 3" },
  { label: "I-CREDIT 350", value: "i-credit 350" },
  { label: "E-CASH 1", value: "e-cash 1" },
  { label: "E-CASH 3 VERSION 2", value: "e-cash 3 version 2" },
  { label: "CNC", value: "cnc" },
  { label: "KUNKUN", value: "kunkun" },
  { label: "E-CASH 3", value: "e-cash 3" },
  { label: "SECURE METERS LTD", value: "secure meters ltd" },
  { label: "ELSTER", value: "elster" },
  { label: "ABB", value: "abb" },
  { label: "HUAWEI-WASION", value: "huawei-wasion" },
  { label: "Holley", value: "holley" },
  { label: "AUTOMETER", value: "autometer" },
  { label: "MMS-CLOU", value: "mms-clou" },
  { label: "MMS", value: "mms" },
  { label: "SMART CASH (BOT)", value: "smart cash (bot)" },
  { label: "KAMSTRUP", value: "kamstrup" },
  { label: "MMS-SECURE", value: "mms-secure" },
  { label: "LANDIS&GYR", value: "landis&gyr" },
  { label: "Nuri", value: "nuri" },
  { label: "WUHU", value: "wuhu" },
  { label: "GANZ", value: "ganz" },
  { label: "HUAWEI-CIS PREPAY", value: "huawei-cis prepay" },
  { label: "POSTPAID", value: "postpaid" },
  { label: "MMS-HEXING", value: "mms-hexing" },
  { label: "PNS", value: "pns" },
  { label: "CLOU", value: "clou" },
  { label: "MBH CLOU", value: "mbh_clou" },
  { label: "MBH POWER", value: "MBH POWER" },
  { label: "ECASH-4", value: "ecash-4" },
  { label: "IMES", value: "imes" },
  { label: "BOT", value: "bot" },
  { label: "GEC", value: "gec" },
  { label: "KAMSTRUP 2", value: "kamstrup 2" },
  { label: "M&I", value: "m&i" },
  { label: "GEM", value: "gem" },
  { label: "CAPITAL", value: "capital" },
  { label: "EDMI", value: "edmi" },
  { label: "PowerCom Din Rail", value: "powercom din rail" },
  { label: "LONGI", value: "longi" },
  { label: "Powercom", value: "powercom" },
  { label: "MHDC", value: "mhdc" },
  { label: "SLT", value: "slt" },
  { label: "DENGLI", value: "dengli" },
  { label: "ELMET", value: "elmet" },
  { label: "PRI (SECURE SPRINT)", value: "pri (secure sprint)" },
  { label: "KAIFA", value: "kaifa" },
  { label: "Empower Smart", value: "empower smart" },
  { label: "SECURE SPRINT 350", value: "secure sprint 350" },
  { label: "MMS-WASION", value: "mms-wasion" },
  { label: "Saral", value: "saral" },
  { label: "ALPHA POWER", value: "alpha power" },
  { label: "SCHLUMBERGER", value: "schlumberger" },
  { label: "MMS-ITRON", value: "MMS-ITRON" },
  { label: "CONLOG", value: "CONLOG" },
  { label: "MMS-BUTTERFIELD", value: "MMS-BUTTERFIELD" },
  { label: "MMS-NURI", value: "MMS-NURI" },
  { label: "MMS-CONLOG", value: "MMS-CONLOG" },
  { label: "MMS-GEM", value: "MMS-GEM" },
  { label: "MMS-LONGI", value: "MMS-LONGI" },
  { label: "MMS-HUMANLITECH", value: "MMS-HUMANLITECH" },
  { label: "MMS-BRAINFIELD", value: "mms-brainfield" },
  { label: "MMS-OTTOBI", value: "MMS-OTTOBI" },
];

export const vendorMeterTypes = [
  { label: "ECASH1_3", value: "ecash1_3" },
  { label: "NURI", value: "nuri" },
  { label: "BXC", value: "bxc" },
  { label: "BOT", value: "bot" },
  { label: "SMARTG", value: "smartg" },
  { label: "HOLLEY", value: "holley" },
  { label: "SCN_CLOU", value: "scn_clou" },
  { label: "ALPHA", value: "alpha" },
  { label: "MBH CLOU", value: "mbh_clou" },
  { label: "PNS", value: "pns" },
  { label: "IMES", value: "imes" },
  { label: "MMS", value: "mms" },
  { label: "ECASH4", value: "ecash4" },
  { label: "HUAWEI", value: "huawei" },
  { label: "INEST", value: "inest" },
];

export const regions = [
  { label: "WESTERN", value: "WESTERN" },
  { label: "CENTRAL", value: "CENTRAL" },
  { label: "TEMA", value: "TEMA" },
  { label: "VOLTA", value: "VOLTA" },
  { label: "EASTERN", value: "EASTERN" },
  { label: "ACCRA EAST", value: "ACCRA EAST" },
  { label: "ACCRA WEST", value: "ACCRA WEST" },
  { label: "ASHANTI SBU", value: "ASHANTI SBU" },
];

export const districts = [
  { label: "TAKORADI", value: "TAKORADI" },
  { label: "SEKONDI", value: "SEKONDI" },
  { label: "TARKWA", value: "TARKWA" },
  { label: "AXIM", value: "AXIM" },
  { label: "SEFWI WIAWSO", value: "SEFWI WIAWSO" },
  { label: "BIBIANI", value: "BIBIANI" },
  { label: "JUABESO", value: "JUABESO" },
  { label: "ASANKRAGWA", value: "ASANKRAGWA" },
  { label: "ENCHI", value: "ENCHI" },
  { label: "HALF ASSINI", value: "HALF ASSINI" },
  { label: "AGONA NKWANTA", value: "AGONA NKWANTA" },
  { label: "BOGOSO", value: "BOGOSO" },
  { label: "CAPE COAST", value: "CAPE COAST" },
  { label: "SALTPOND", value: "SALTPOND" },
  { label: "SWEDRU", value: "SWEDRU" },
  { label: "WINNEBA", value: "WINNEBA" },
  { label: "BREMAN ASIKUMA", value: "BREMAN ASIKUMA" },
  { label: "KWABENYA", value: "KWABENYA" },
  { label: "LEGON", value: "LEGON" },
  { label: "TESHIE", value: "TESHIE" },
  { label: "DODOWA", value: "DODOWA" },
  { label: "AKUAPIM MAMPONG", value: "AKUAPIM MAMPONG" },
  { label: "KASOA", value: "KASOA" },
  { label: "DANYAME", value: "DANYAME" },
  { label: "SUAME", value: "SUAME" },
  { label: "ABUAKWA", value: "ABUAKWA" },
  { label: "ADUM", value: "ADUM" },
  { label: "ROMAN RIDGE", value: "ROMAN RIDGE" },
  { label: "MAKOLA", value: "MAKOLA" },
  { label: "ACHIMOTA", value: "ACHIMOTA" },
  { label: "DANSOMAN", value: "DANSOMAN" },
  { label: "WINNEBA", value: "WINNEBA" },
  { label: "HO", value: "HO" },
  { label: "ABLEKUMA", value: "ABLEKUMA" },
  { label: "BORTIANOR", value: "BORTIANOR" },
  { label: "NSAWAM", value: "NSAWAM" },
  { label: "TEMA NORTH", value: "TEMA NORTH" },
  { label: "TEMA SOUTH", value: "TEMA SOUTH" },
  { label: "KROBO", value: "KROBO" },
  { label: "PRAMPRAM", value: "PRAMPRAM" },
  { label: "ASHIAMAN", value: "ASHIAMAN" },
  { label: "NUNGUA", value: "NUNGUA" },
  { label: "AFIENYA", value: "AFIENYA" },
  { label: "KOFORIDUA", value: "KOFORIDUA" },
  { label: "SUHUM", value: "SUHUM" },
  { label: "NKAWKAW", value: "NKAWKAW" },
  { label: "KIBI", value: "KIBI" },
  { label: "TAFO", value: "TAFO" },
  { label: "CAPE COAST", value: "CAPE COAST" },
  { label: "KASOA SOUTH", value: "KASOA SOUTH" },
  { label: "MAKOLA", value: "MAKOLA" },
  { label: "TEMA NORTH", value: "TEMA NORTH" },
  { label: "TEMA SOUTH", value: "TEMA SOUTH" },
  { label: "NUNGUA", value: "NUNGUA" },
  { label: "AFIENYA", value: "AFIENYA" },
  { label: "ASHAIMAN", value: "ASHAIMAN" },
  { label: "ABLEKUMA", value: "ABLEKUMA" },
  { label: "BORTIANOR", value: "BORTIANOR" },
  { label: "KORLE-BU", value: "KORLE-BU" },
  { label: "NSAWAM", value: "NSAWAM" },
];

export const issueTypeData = [
  { label: "Abnormal Consumption", value: "Abnormal Consumption" },
  {
    label: "Emergency and/or Faulty Meter",
    value: "Emergency and/or Faulty Meter",
  },
  { label: "Good Customer", value: "Good Customer" },
  {
    label: "Low Balance or Service Interruption Warning",
    value: "Low Balance or Service Interruption Warning",
  },
];

export const vendorInvoiceFilters = [
  {
    name: "InvoiceNumber",
    text: "Invoice Number",
    elementType: "text-input",
  },
  {
    name: "VendorId",
    text: "Vendor ID",
    elementType: "text-input",
  },
  {
    name: "VendorInstoreId",
    text: "Vendor Instore ID",
    elementType: "text-input",
  },
  {
    name: "paymentAccountNumber",
    text: "Payment Account Number",
    elementType: "text-input",
  },
  {
    name: "GovInvoiceNumber",
    text: "Bank Invoice Number",
    elementType: "text-input",
  },
  {
    name: "VendorMeterType",
    text: "Vendor Meter Type",
    elementType: "select",
    options: vendorMeterTypes,
  },
  {
    name: "Recipient",
    text: "Phone Number",
    elementType: "text-input",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "COMPLETED", value: "completed" },
      { label: "FAILED", value: "failed" },
      { label: "PAID", value: "paid" },
      { label: "UNPAID", value: "unpaid" },
      { label: "PENDING", value: "pending" },
      { label: "CANCELLED", value: "cancelled" },
      { label: "UNKNOWN", value: "unknown" },
    ],
  },
  {
    name: "Region",
    text: "Region",
    elementType: "select",
    options: regions,
  },
  {
    name: "District",
    text: "District",
    elementType: "select",
    options: districts,
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const vendorFilters = [
  { name: "Name", text: "Name", elementType: "text-input" },
  { name: "Id", text: "ID", elementType: "text-input" },
  { name: "VendorId", text: "Vendor ID", elementType: "text-input" },
  { name: "VendorName", text: "Vendor Name", elementType: "text-input" },
  { name: "PhoneNumber", text: "Phone Number", elementType: "text-input" },
  { name: "CompanyName", text: "Company Name", elementType: "text-input" },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
    ],
  },
  {
    name: "MeterType",
    text: "Meter Type",
    elementType: "select",
    options: vendorMeterTypes,
  },
  { name: "StartCreatedAt", text: "Start Date", elementType: "date" },
  { name: "EndCreatedAt", text: "End Date", elementType: "date" },
];

export const walletFilters = [
  {
    name: "Id",
    text: "ID",
  },
  {
    name: "MobileNumber",
    text: "Mobile Number",
  },
  {
    name: "GhanaCardId",
    text: "Ghana Card ID",
  },
  {
    name: "WalletType",
    text: "Wallet Type",
  },
  {
    name: "AccountNumber",
    text: "Account Number",
  },
  {
    name: "FineractSavingsId",
    text: "Fineract Savings ID",
  },
  {
    name: "ProviderName",
    text: "Provider Name",
  },
  {
    name: "Status",
    text: "Status",
  },
];

export const transactionFilters = [
  {
    name: "PhoneNumber",
    text: "Phone Number",
    elementType: "text-input",
  },
  {
    name: "MeterNumber",
    text: "Meter Number",
    elementType: "text-input",
  },
  {
    name: "PaymentProcessorId",
    text: "Invoice Number",
    elementType: "text-input",
  },
  {
    name: "AccountNumber",
    text: "Account Number",
    elementType: "text-input",
  },
  {
    name: "PaymentAccountNumber",
    text: "Payment Account Number",
    elementType: "text-input",
  },
  {
    name: "paymentChannel",
    text: "Payment Channel",
    elementType: "select",
    options: [
      { label: "PowerApp Balance", value: "PowerApp Balance" },
      { label: "Mobilemoney", value: "mobilemoney" },
      { label: "G-Money", value: "g-money" },
      { label: "Fineract", value: "fineract" },
      { label: "Ghana.gov", value: "ghana.gov" },
      { label: "Card", value: "card" },
      { label: "OLTPV", value: "oltpv" },
    ],
  },
  {
    name: "isPaymentComplete",
    text: "Payment Completed",
    elementType: "select",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    name: "RechargeSource",
    text: "Recharge Source",
    elementType: "select",
    options: [
      { label: "Mobile App", value: "mobile-app" },
      { label: "Staff-Portal", value: "staffportal" },
      { label: "USSD", value: "USSD" },
      { label: "Offline-Cashier", value: "offlineCashier" },
      { label: "Oltpv", value: "oltpv" },
    ],
  },
  {
    name: "MeterType",
    text: "Meter Type",
    options: meterTypes,
    elementType: "select",
  },
  {
    name: "MeterCategory",
    text: "Meter Category",
    elementType: "select",
    options: [
      { label: "Prepaid", value: "PREPAID" },
      { label: "Postpaid", value: "POSTPAID" },
      { label: "Prepaid Offline", value: "PREPAID OFFLINE" },
      { label: "Non-Smart", value: "NON SMART" },
      { label: "SLT", value: "SLT" },
    ],
  },
  {
    name: "VendorAccountId",
    text: "Vendor Account ID",
    options: [],
    elementType: "select",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Paid", value: "paid" },
      { label: "Refund Pending", value: "refund pending" },
      { label: "Failed", value: "failed" },
      { label: "Unknown", value: "unknown" },
      { label: "Success", value: "success" },
      { label: "Unpaid", value: "unpaid" },
      { label: "Refunded", value: "refunded" },
      { label: "Completed", value: "completed" },
    ],
  },
  {
    name: "reversalStatus",
    text: "Reversal Status",
    elementType: "select",
    options: [
      { label: "Completed", value: "completed" },
      { label: "Completed and Refunded", value: "completed and refunded" },
      { label: "In progress", value: "in progress" },
      { label: "Rejected", value: "denied" },
      { label: "Pending", value: "pending" },
    ],
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const customerStatementFilters = [
  {
    name: "MeterNumber",
    text: "Meter Number",
    elementType: "text-input",
  },
  {
    name: "AccountNumber",
    text: "Account Number",
    elementType: "text-input",
  },
  {
    name: "PaymentAccountNumber",
    text: "Payment Account Number",
    elementType: "text-input",
  },
  {
    name: "paymentChannel",
    text: "Payment Channel",
    elementType: "select",
    options: [
      { label: "PowerApp Balance", value: "PowerApp Balance" },
      { label: "Mobilemoney", value: "mobilemoney" },
      { label: "G-Money", value: "g-money" },
      { label: "Fineract", value: "fineract" },
      { label: "Ghana.gov", value: "ghana.gov" },
      { label: "Card", value: "card" },
      { label: "OLTPV", value: "oltpv" },
    ],
  },
  {
    name: "MeterType",
    text: "Meter Type",
    options: meterTypes,
    elementType: "select",
  },
  {
    name: "MeterCategory",
    text: "Meter Category",
    elementType: "select",
    options: [
      { label: "Prepaid", value: "PREPAID" },
      { label: "Postpaid", value: "POSTPAID" },
      { label: "Prepaid Offline", value: "PREPAID OFFLINE" },
      { label: "Non-Smart", value: "NON SMART" },
      { label: "SLT", value: "SLT" },
    ],
  },
  {
    name: "VendorAccountId",
    text: "Vendor Account ID",
    options: [],
    elementType: "select",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Unpaid", value: "unpaid" },
      { label: "Failed", value: "failed" },
      { label: "Success", value: "success" },
    ],
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const batchPaymentDetailsFilter = [
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Paid", value: "paid" },
      { label: "Pending", value: "pending" },
      { label: "Failed", value: "failed" },
      { label: "Success", value: "success" },
      { label: "Unpaid", value: "unpaid" },
      { label: "Completed", value: "completed" },
    ],
  },
];

export const bankPayTransactionFilters = [
  ...transactionFilters.filter((filter) => filter.name !== "paymentChannel"),
];

export const nonSmartTransactionFilters = transactionFilters.filter(
  (filter) => filter.name !== "MeterCategory"
);

export const flatrateTransactionFilters = nonSmartTransactionFilters;

export const reversalRequestFilters = [
  {
    name: "ReversalType",
    text: "Reversal Type",
    elementType: "select",
    options: [
      { label: "Energy", value: "energy" },
      { label: "Non-Energy", value: "nonenergy" },
    ],
  },
  ...transactionFilters,
];

export const StaffPerformanceTaskFilters = [
  { name: "DateFrom", text: "Start Date", elementType: "date" },
  { name: "DateTo", text: "End Date", elementType: "date" },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Not Started", value: "new" },
      { label: "In Progress", value: "inprogress" },
      { label: "Completed", value: "completed" },
    ],
  },
  {
    name: "RiskLevel",
    text: "Risk Level",
    elementType: "select",
    options: [
      { label: "No risk", value: "No risk" },
      {
        label: "Low risk",
        value: "Low risk",
      },
      { label: "Medium Risk", value: "Medium Risk" },
      {
        label: "High Risk",
        value: "High Risk",
      },
    ],
  },
  {
    name: "PostpaidDelinquency",
    text: "Delinquency Category",
    elementType: "select",
    options: [
      { label: "SLT PostPaid", value: "1" },
      { label: "Priority PostPaid", value: "2" },
      { label: "Regular PostPaid", value: "3" },
    ],
  },
];

export const staffPerformanceReportFilters = [
  {
    name: "TypeOfConsumptionAnomaly",
    text: "Type Of Consumption Anomaly",
    elementType: "select",
    options: [
      {
        label: "Illegal Connection",
        value: "Illegal Connection",
      },
      {
        label: "Direct Service Connection",
        value: "Direct Service Connection",
      },
      {
        label: "Foreign Meter",
        value: "Foreign Meter",
      },
      { label: "Non-Purchasing Prepaid", value: "Non-Purchasing Prepaid" },
      { label: "Wrong Date & Time", value: "Wrong Date & Time" },
      { label: "Faulty Meter", value: "Faulty Meter" },
      {
        label: "Uncaptured Meter / Customer",
        value: "Uncaptured Meter / Customer",
      },
      { label: "Burnt Meter", value: "Burnt Meter" },
      {
        label: "Blank Screen/Faulty Screen",
        value: "Blank Screen/Faulty Screen",
      },
      { label: "Wrong Tariff Class", value: "Wrong Tariff Class" },
      { label: "Faulty Card", value: "Faulty Card" },
      { label: "Non - Revised Tariff", value: "Non - Revised Tariff" },
      { label: "Error Message Display", value: "Error Message Display" },
      {
        label: "Delinquent Postpaid Customer",
        value: "Delinquent Postpaid Customer",
      },
    ],
  },
  {
    name: "CorrectiveAction",
    text: "Corrective Actions",
    elementType: "select",
    options: [
      {
        label: "Change Billing to Flat Rate (FR)",
        value: "flatrate",
      },
      {
        label: "Issue Consumption Anomaly Bill (CAS)",
        value: "anomaly surcharge",
      },
      {
        label: "Generate Both FR & CAS",
        value: "flatrate and anomaly surcharge",
      },
      { label: "No Issue Detected, Meter is Fine", value: "no issue" },
      { label: "Take Payment Now", value: "take payment" },
      { label: "Issue Disconnection Notice", value: "disconnection notice" },
      {
        label: "Request for Immediate Disconnection",
        value: "request for immediate disconnection",
      },
      { label: "Report Payment Issue", value: "report payment issue" },
    ],
  },
  {
    name: "RiskLevel",
    text: "Risk Level",
    elementType: "select",
    options: [
      { label: "No risk", value: "No risk" },
      {
        label: "Low risk",
        value: "Low risk",
      },
      { label: "Medium Risk", value: "Medium Risk" },
      {
        label: "High Risk",
        value: "High Risk",
      },
    ],
  },
  {
    name: "PostpaidDelinquency",
    text: "Delinquency Category",
    elementType: "select",
    options: [
      { label: "SLT PostPaid", value: "1" },
      { label: "Priority PostPaid", value: "2" },
      { label: "Regular PostPaid", value: "3" },
    ],
  },
  { name: "DateFrom", text: "Start Date", elementType: "date" },
  { name: "DateTo", text: "End Date", elementType: "date" },
];

export const staffDisbursalReportFilters = [
  { name: "DateFrom", text: "Start Date", elementType: "date" },
  { name: "DateTo", text: "End Date", elementType: "date" },
];

export const staffFundsDisbursalFilters = [
  { name: "DateFrom", text: "Start Date", elementType: "date" },
  { name: "DateTo", text: "End Date", elementType: "date" },
  {
    name: "paymentChannel",
    text: "Payment method",
    elementType: "select",
    options: [
      { label: "Mobilemoney", value: "mobilemoney" },
      { label: "G-Money", value: "g-money" },
      { label: "Fineract", value: "fineract" },
      { label: "Ghana.gov", value: "ghana.gov" },
      { label: "Bankpay", value: "bankpay" },
      { label: "Card", value: "card" },
    ],
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Pending", value: "pending" },
      { label: "Completed", value: "completed" },
    ],
  },
];

export const challengedTransactionFilters = [
  {
    name: "PhoneNumber",
    text: "Phone Number",
    elementType: "text-input",
  },
  {
    name: "MeterNumber",
    text: "Meter Number",
    elementType: "text-input",
  },
  {
    name: "PaymentProcessorId",
    text: "Invoice Number",
    elementType: "text-input",
  },
  {
    name: "AccountNumber",
    text: "Account Number",
    elementType: "text-input",
  },
  {
    name: "PaymentAccountNumber",
    text: "Payment Account Number",
    elementType: "text-input",
  },
  {
    name: "paymentChannel",
    text: "Payment Channel",
    elementType: "select",
    options: [
      { label: "Mobilemoney", value: "mobilemoney" },
      { label: "G-Money", value: "g-money" },
      { label: "Fineract", value: "fineract" },
      { label: "Ghana.gov", value: "ghana.gov" },
      { label: "Bankpay", value: "bankpay" },
      { label: "Card", value: "card" },
    ],
  },
  {
    name: "MeterType",
    text: "Meter Type",
    options: meterTypes,
    elementType: "select",
  },
  {
    name: "RechargeSource",
    text: "Recharge Source",
    elementType: "select",
    options: [
      { label: "Mobile App", value: "mobile-app" },
      { label: "Staff-Portal", value: "staffportal" },
      { label: "USSD", value: "USSD" },
      { label: "Offline-Cashier", value: "offlineCashier" },
      { label: "Oltpv", value: "oltpv" },
    ],
  },
  {
    name: "MeterCategory",
    text: "Meter Category",
    elementType: "select",
    options: [
      { label: "Prepaid", value: "PREPAID" },
      { label: "Postpaid", value: "POSTPAID" },
      { label: "Prepaid Offline", value: "PREPAID OFFLINE" },
      { label: "Non-Smart", value: "NON+SMART" },
    ],
  },
  {
    name: "VendorAccountId",
    text: "Vendor Account ID",
    options: [],
    elementType: "select",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Paid", value: "paid" },
      { label: "Refund Pending", value: "refund pending" },
      { label: "Failed", value: "failed" },
      { label: "Unknown", value: "unknown" },
    ],
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const oltpVendorFilters = [
  {
    name: "AccountNumber",
    text: "Account Number",
    elementType: "text-input",
  },
  { name: "ClientId", text: "Client ID", elementType: "text-input" },
  { name: "Name", text: "Vendor Name", elementType: "select" },
  { name: "StartCreatedAt", text: "Start Date", elementType: "date" },
  { name: "EndCreatedAt", text: "End Date", elementType: "date" },
];

export const oltpvTransactionsFilters = [
  {
    name: "TransactionType",
    text: "Transaction Type",
    elementType: "select",
    options: [
      { label: "Withdrawal", value: "2" },
      { label: "Deposit", value: "1" },
    ],
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const topupFilters = [
  {
    name: "VendorAccountId",
    text: "Vendor Account ID",
    elementType: "text-input",
  },
  { name: "Email", text: "Email", elementType: "text-input" },
  {
    name: "GovInvoiceNumber",
    text: "Bank Invoice Number",
    elementType: "text-input",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Success", value: "success" },
      { label: "Paid", value: "paid" },
      { label: "Pending", value: "pending" },
      { label: "Failed", value: "failed" },
      { label: "Created", value: "created" },
      { label: "Refunded", value: "refunded" },
      { label: "Unknown", value: "unknown" },
    ],
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const oltpvManagementFilters = [
  { name: "PhoneNumber", text: "Phone Number", elementType: "text-input" },
  { name: "Name", text: "Name", elementType: "text-input" },
  {
    name: "VendorAccountId",
    text: "Vendor Account ID",
    elementType: "select",
    options: [],
  },
  { name: "Email", text: "Email", elementType: "text-input" },
  {
    name: "HasSocialLogin",
    text: "Has Social Login",
    elementType: "select",
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
  },
  { name: "StartCreatedAt", elementType: "date", text: "Start Date" },
  { name: "EndCreatedAt", elementType: "date", text: "End Date" },
];

export const cmsUsersFilters = [
  { name: "StaffId", elementType: "text-input", text: "Staff ID" },
  { name: "Region", elementType: "text-input", text: "Region" },
  { name: "District", elementType: "text-input", text: "District" },
  { name: "Office", elementType: "text-input", text: "Office" },
  { name: "Status", elementType: "text-input", text: "Status" },
  {
    name: "UserProfile",
    text: "User Role",
    elementType: "select",
    options: [
      { label: "Administrator", value: "Administrator" },
      { label: "Cashier", value: "Cashier" },
      { label: "Staff", value: "Staff" },
      { label: "Collector", value: "Collector" },
    ],
  },
  { name: "Email", elementType: "text-input", text: "Email" },
  { name: "FullName", elementType: "text-input", text: "FullName" },
  { name: "DoB", elementType: "date", text: "Date of Birth" },
  { name: "StartCreatedAt", elementType: "date", text: "Start Date" },
  { name: "EndCreatedAt", elementType: "date", text: "End Date" },
];

export const offlineBondedCashierFilters = [
  { name: "PhoneNumber", text: "Phone Number", elementType: "number-input" },
  { name: "Id", text: "ID", elementType: "text-input" },
  { name: "Name", text: "Name", elementType: "text-input", options: [] },
  { name: "Email", text: "Email", elementType: "email-input" },
  { name: "StartCreatedAt", text: "Start Date", elementType: "date" },
  { name: "EndCreatedAt", text: "End Date", elementType: "date" },
];

export const offlineBondedCashierInvoicesFilters = [
  { name: "Name", text: "Name", elementType: "text-input" },
  { name: "Email", text: "Email", elementType: "text-input" },
  {
    name: "PaymentReferenceId",
    text: "Payment Reference ID",
    elementType: "text-input",
  },
  {
    name: "GovInvoiceNumber",
    text: "GOV Invoice Number",
    elementType: "text-input",
  },
  {
    name: "PaymentSourceNumber ",
    text: "Mobile Money Number",
    elementType: "text-input",
  },
  {
    name: "FineractResourceId",
    text: "Fineract Resource ID",
    elementType: "text-input",
  },
  {
    name: "ObcFineractId",
    text: "OBC Fineract ID",
    elementType: "text-input",
  },
  {
    name: "PaymentChannelId",
    text: "Mobile Money Transaction ID",
    elementType: "text-input",
  },
  { name: "PhoneNumber", text: "Recipient", elementType: "text-input" },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "FAILED", value: "failed" },
      { label: "PAID", value: "paid" },
      { label: "PENDING", value: "pending" },
    ],
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const meterInquiryFilters = [
  { name: "Id", text: "ID", elementType: "text-input" },
  { name: "PhoneNumber", text: "Phone Number", elementType: "text-input" },
  { name: "MeterNumber", text: "Meter Number", elementType: "text-input" },
  { name: "AccountNumber", text: "Account Number", elementType: "text-input" },
  { name: "MeterId", text: "Meter ID", elementType: "text-input" },
  { name: "NameOnMeter", text: "Name On Meter", elementType: "text-input" },
  {
    name: "MeterType",
    text: "Meter Type",
    options: meterTypes,
    elementType: "select",
  },
  {
    name: "MeterCategory",
    text: "Meter Category",
    elementType: "select",
    options: [
      { label: "Prepaid", value: "PREPAID" },
      { label: "Postpaid", value: "POSTPAID" },
      { label: "Prepaid Offline", value: "PREPAID OFFLINE" },
      { label: "Non-Smart", value: "NON SMART" },
      { label: "SLT", value: "SLT" },
    ],
  },
  {
    name: "StatusCode",
    text: "Status Code",
    elementType: "select",
    options: [
      { label: "0", value: "0" },
      { label: "1001", value: "1001" },
      { label: "2002", value: "2002" },
    ],
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Paid", value: "paid" },
      { label: "Refund Pending", value: "refund pending" },
      { label: "Failed", value: "failed" },
      { label: "Unknown", value: "unknown" },
      { label: "Success", value: "success" },
      { label: "Unpaid", value: "unpaid" },
      { label: "Refunded", value: "refunded" },
      { label: "Completed", value: "completed" },
    ],
  },
];

export const customerFilters = [
  { name: "Name", text: "Customer Name", elementType: "text-input" },
  { name: "MobileNumber", text: "Mobile Number", elementType: "text-input" },
  { name: "StartCreatedAt", text: "Start Date", elementType: "date" },
  { name: "EndCreatedAt", text: "End Date", elementType: "date" },
];

export const refundHistoryFilters = [
  {
    name: "TransactionType",
    text: "Transaction Type",
    elementType: "select",
    options: [
      { label: "Withdrawal", value: "2" },
      { label: "Deposit", value: "1" },
    ],
  },
  {
    name: "TransactionId",
    text: "Transaction ID",
    elementType: "text-input",
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const usersFilters = [
  { name: "Name", text: "Name", elementType: "text-input" },
  { name: "Phone", text: "Phone Number", elementType: "text-input" },
  { name: "Email", text: "Email", elementType: "text-input" },
  {
    name: "IsActive",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Active", value: "true" },
      { label: "Inactive", value: "false" },
    ],
  },
  {
    name: "Role",
    text: "Role",
    elementType: "select",
    options: USER_ROLES.map((role) => ({
      label: role?.name,
      value: role?.value,
    })),
  },
];

export const invoiceFilters = [
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
  {
    name: "InvoiceNumber",
    text: "Invoice Number",
    elementType: "text-input",
  },
  { name: "recipient", text: "Recipient", elementType: "text-input" },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Paid", value: "paid" },
      { label: "Pending", value: "pending" },
      { label: "Failed", value: "failed" },
    ],
  },
];

export const serviceRequestFilters = [
  {
    name: "Category",
    text: "Category",
    elementType: "select",
    options: [
      { label: "STANDARD", value: "standard" },
      { label: "EXPRESS", value: "express" },
      { label: "PREMIUM", value: "premium" },
    ],
  },
  {
    name: "Type",
    text: "Type",
    elementType: "select",
    options: [
      { label: "NEW SERVICE", value: "new service" },
      { label: "SEPARATE METER", value: "separate meter" },
      { label: "ADDITIONAL LOAD", value: "additional load" },
    ],
  },
  {
    name: "TypeOfService",
    text: "Type Of Service",
    elementType: "select",
    options: [
      { label: "SINGLE PHASE", value: "single phase" },
      { label: "THREE PHASE", value: "three phase" },
    ],
  },
  {
    name: "PurposeOfService",
    text: "Purpose Of Service",
    elementType: "text-input",
  },
  { name: "HouseNumber", text: "House Number", elementType: "text-input" },
  { name: "Community", text: "Community", elementType: "text-input" },
  {
    name: "NearestLandMark",
    text: "Nearest Land Mark",
    elementType: "text-input",
  },
  { name: "GhPostAddress", text: "Gh Post Address", elementType: "text-input" },
  { name: "PhoneNumber", text: "Phone Number", elementType: "text-input" },
  { name: "Status", text: "Status", elementType: "text-input" },
  {
    name: "TypeOfId",
    text: "Type of Identification",
    elementType: "text-input",
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];

export const taskFilters = [
  {
    name: "Title",
    text: "Task Type",
    elementType: "select",
    options: [
      { label: "STANDARD", value: "standard" },
      { label: "EXPRESS", value: "express" },
      { label: "PREMIUM", value: "premium" },
    ],
  },
  {
    name: "Status",
    text: "Task Status",
    elementType: "select",
    options: [
      { label: "Not Started", value: "new" },
      { label: "Tasks In Progress", value: "inprogress" },
      { label: "Completed Tasks", value: "completed" },
      { label: "Discarded Tasks", value: "discarded" },
      { label: "Overdue Tasks", value: "overdue" },
    ],
  },
  {
    name: "AssignmentStatus",
    text: "Task Assignment Status",
    elementType: "select",
    options: [
      { label: "Assigned Tasks", value: "assigned" },
      { label: "Unassigned Tasksk", value: "unassigned" },
    ],
  },
];

export const userFilters = [
  {
    name: "CustomerName",
    text: "Customer Name",
    elementType: "text-input",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Resolved", value: "Resolved" },
      { label: "Escalated to Finance", value: "Escalated to Finance" },
    ],
  },
];

export const unsuccessfulTransactionStatuses = [
  "failed",
  "unknown",
  "confirmation failed",
];

export const TELCOS = ["MTN", "AIRTELTIGO", "VODAFONE"];

export enum reversalActions {
  COMPLETE = "Complete",
  COMPLETE_AND_REFUND = "Complete and Refund",
  REJECT = "Reject",
  REFUND = "Refund",
  REVERSE_WITHOUT_REFUND = "Reverse without Refund",
}

export const durationFilters = [
  { label: "This Week", value: "week" },
  { label: "This Month", value: "month" },
  { label: "This Year", value: "year" },
];

export const eventFilters = [
  { label: "Application Fee Generated", value: "ApplicationFeeGenerated" },
  { label: "Application Fee Paid", value: "ApplicationFeePaid" },
  { label: "Connection Fee Generated", value: "ConnectionFeeGenerated" },
  { label: "Inspection Initiated", value: "InspectionInitiated" },
  { label: "Inspection Assigned", value: "InspectionAssigned" },
  { label: "Inspection Successful", value: "InspectionSuccessful" },
  { label: "Inspection Failed", value: "InspectionFailed" },
  { label: "Contract Generated", value: "ContractGenerated" },
  { label: "Contract Signed", value: "ContractSigned" },
  { label: "Installation Initiated", value: "InstallationInitiated" },
  { label: "Installation Assigned", value: "InstallationAssigned" },
  { label: "Installation Successful", value: "InstallationSuccessful" },
  { label: "Installation Failed", value: "InstallationFailed" },
  { label: "Connection Fee Paid", value: "ConnectionFeePaid" },
  {
    label: "Customer Tariff Class Updated",
    value: "CustomerTariffClassUpdated",
  },
  { label: "Service Request Completed", value: "ServiceRequestCompleted" },
  {
    label: "Customer Account Detail Change Approved",
    value: "CustomerAccountDetailChangeApproved",
  },
  {
    label: "Customer Account Detail Change Rejected",
    value: "CustomerAccountDetailChangeRejected",
  },
  { label: "New Meter Installed", value: "NewMeterInstalled" },
  { label: "Meter Replaced", value: "MeterReplaced" },
  { label: "Customer Updated", value: "CustomerUpdated" },
  { label: "Meter Reading", value: "MeterReading" },
  { label: "Meter Reading Exception", value: "MeterReadingException" },
  { label: "Meter Reading Anomaly", value: "MeterReadingAnomaly" },
  { label: "Regularization Completed", value: "RegularizationCompleted" },
  { label: "Service Request Approved", value: "ServiceRequestApproved" },
  { label: "Service Request Rejected", value: "ServiceRequestRejected" },
  {
    label: "Shep Regularization Completed",
    value: "ShepRegularizationCompleted",
  },
];
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const dataBundleDisbursalFilters = [
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Pending", value: "pending" },
      { label: "Completed", value: "completed" },
    ],
  },
];

export const CustomerComplaints = {
  FAULTY_METER: "faulty meter",
  ISSUE_WITH_POSTPAID_BILL: "issue with Postpaid Bill",
  NO_PURCHASING_INFORMATION: "No Purchasing information",
  NO_GEOCODE_OR_GPS_ON_METER: "No Geocode or GPS on meter",
  ILLEGAL_CONNECTIONS: "illegal connection",
  PAYMENT_RELATED_ISSUES: "payment related issue",
  STOLEN_METER: "stolen meter",
  POLE_ISSUES: "pole issue",
  TRANSFORMER_ISSUES: "transformer issue",
  PREPAID_METER_NOT_FOUND: "prepaid meter not found",
  NEW_SERVICE_CONNECTION: "new service request",
  UNCREDITED_PAYMENTS: "Uncredited Payments",
  CHANGE_OF_ACCOUNT_DETAILS: "change of account details",
  TARRIF_CLASS_CHANGE: "tariff class change",
  CUSTOMER_STATEMENT_REQUEST: "customer statement",
  REQUEST_FOR_TEMPORARY_DISCONNECTION: "temporary disconnection",
  METER_TRANSFER_REQUEST: "meter transfer",
  PAYMENT_PLAN_REQUEST: "payment plan",
  METER_TEST_REQUEST: "meter test",
  NO_POSTPAID_BILL_ISSUED_FOR_MORE_THAN_ONE_MONTH:
    "No postpaid bill issued for more than 1 month",
  FAULTY_METER_REPORTED_BY_METER_READER:
    "Faulty Meter Reported By Meter Reader",
  EXTENDED_USE_OF_EMERGENCY_POWER: "Extended use of Emergency Power",
  PAYMENTS_WITH_NO_ISSUED_BILL: "Payments with No Issued Bill",
  NO_PAYMENT_OF_POSTPAID_BILL: "No payment of postpaid bill in about .* months",
} as const;

export const batchTransactionFilters = [
  {
    name: "govInvoiceNumber",
    text: "GOV Invoice Number",
    elementType: "text-input",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Paid", value: "paid" },
      { label: "Refund Pending", value: "refund pending" },
      { label: "Failed", value: "failed" },
      { label: "Unknown", value: "unknown" },
      { label: "Success", value: "success" },
      { label: "Unpaid", value: "unpaid" },
      { label: "Refunded", value: "refunded" },
      { label: "Completed", value: "completed" },
    ],
  },
  {
    name: "paymentReferenceId",
    text: "Payment Reference Id",
    elementType: "text-input",
  },
  {
    name: "walletId",
    text: "Wallet Id",
    elementType: "text-input",
  },
  {
    name: "batchType",
    text: "Batch Type",
    elementType: "select",
    options: [
      { label: "Non-Energy", value: "NONENERGY" },
      { label: "Energy", value: "ENERGY" },
    ],
  },
  {
    name: "phoneNumber",
    text: "Phone Number",
    elementType: "text-input",
  },
  {
    name: "customerName",
    text: "Customer Name",
    elementType: "text-input",
  },
  {
    name: "paymentChannel",
    text: "Payment Channel",
    elementType: "select",
    options: [
      { label: "PowerApp Balance", value: "PowerApp Balance" },
      { label: "Mobilemoney", value: "mobilemoney" },
      { label: "G-Money", value: "g-money" },
      { label: "Fineract", value: "fineract" },
      { label: "Ghana.gov", value: "ghana.gov" },
      { label: "Card", value: "card" },
      { label: "OLTPV", value: "oltpv" },
    ],
  },
  {
    name: "paymentProcessorId",
    text: "Payment Processor Id",
    elementType: "text-input",
  },
  {
    name: "paymentProvider",
    text: "Payment Provider",
    elementType: "select",
    options: [{ label: "MTN", value: "mtn" }],
  },

  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];


export const SalesPerformanceFilters = [
  {
    name: "PhoneNumber",
    text: "Phone Number",
    elementType: "text-input",
  },
  {
    name: "PaymentProcessorId",
    text: "Invoice Number",
    elementType: "text-input",
  },
  {
    name: "MeterNumber",
    text: "Meter Number",
    elementType: "text-input",
  },
  {
    name: "AccountNumber",
    text: "Account Number",
    elementType: "text-input",
  },
  {
    name: "MeterType",
    text: "Meter Type",
    options: meterTypes,
    elementType: "select",
  },
  {
    name: "Status",
    text: "Status",
    elementType: "select",
    options: [
      { label: "Paid", value: "paid" },
      { label: "Refund Pending", value: "refund pending" },
      { label: "Failed", value: "failed" },
      { label: "Unknown", value: "unknown" },
      { label: "Success", value: "success" },
      { label: "Unpaid", value: "unpaid" },
      { label: "Refunded", value: "refunded" },
      { label: "Completed", value: "completed" },
    ],
  },
  { name: "StartDate", text: "Start Date", elementType: "date" },
  { name: "EndDate", text: "End Date", elementType: "date" },
];