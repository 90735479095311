import { LinkToAnotherPageWithClassName } from "@/components/Utility";
import { useSession } from "next-auth/react";

const AccessDeniedComponent = () => {
  const { data: session } = useSession();

  return (
    <div className="mt-5 text-center">
      <span className="text-primary mt-5"> {session?.user?.email}</span> is not
      authorized to view this page. Please contact the administrator. <br />
      <LinkToAnotherPageWithClassName
        href="/api/auth/signout"
        className="text-center col-12 mx-auto"
      >
        Click here to logout
      </LinkToAnotherPageWithClassName>
    </div>
  );
};

export default AccessDeniedComponent;
