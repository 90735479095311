import { NavItem } from "@/models";
import { ImageComponent } from "./ImageComponent";
import { LinkToAnotherPageWithClassName } from "./Utility";

const NavItemsComponent: React.FC<{
  item: NavItem;
  name: string;
  isActive: boolean;
}> = ({ item, isActive }) => {
  return (
    <li className={`nav-item ${isActive ? "active" : ""}`} key={item?.name}>
      <LinkToAnotherPageWithClassName
        className="nav-link ecg-nav-link ps-3 pe-2"
        href={item?.url!}
      >
        <span className="nav-link-icon mx-0">
          <ImageComponent src={item?.icon!} alt="icon" />
        </span>
        <div className="nav-link-title ms-2 fs-13">{item?.name}</div>
      </LinkToAnotherPageWithClassName>
    </li>
  );
};

export default NavItemsComponent;
