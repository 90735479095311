import { NavItem } from "@/models";
import { useRouter } from "next/router";
import { LinkToAnotherPageWithClassName } from "./Utility";

const NavDropdownComponent: React.FC<{ item: NavItem }> = ({ item }) => {
  const router = useRouter();
  const isActive = item.url?.split("?")?.[0] === router.pathname;

  return (
    <LinkToAnotherPageWithClassName
      className={`nav-link ${isActive ? "active" : ""}`}
      href={item.url!}
    >
      {item.name}
    </LinkToAnotherPageWithClassName>
  );
};

export default NavDropdownComponent;
