import { getRandomColor } from "@/utils";

const LetterImage = ({ name }: { name: string }) => {
  const firstLetter = name ? name[0].toUpperCase() : "";
  const backgroundColor = getRandomColor();

  return (
    <div
      style={{
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: "bold",
      }}
    >
      {firstLetter}
    </div>
  );
};

export default LetterImage;
