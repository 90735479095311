import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const usePageTitle = () => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const router = useRouter();

  useEffect(() => {
    setPageTitle(document.title);
  }, [router.pathname]);

  return {
    pageTitle,
  };
};

export default usePageTitle;
