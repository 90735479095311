import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useRouter } from "next/router";

const STORAGE_KEY = "connectionState";
const VALIDATION_INTERVAL = 15 * 60 * 1000; // 15 minutes in milliseconds

interface ConnectionState {
  title: string;
  message: string;
  speed: string;
  lastChecked: number;
}

const getInitialState = (): ConnectionState | null => {
  if (typeof window !== "undefined") {
    const storedState = window.localStorage.getItem(STORAGE_KEY);
    return storedState ? JSON.parse(storedState) : null;
  }
  return null;
};

const saveStateToLocalStorage = (state: ConnectionState) => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
};

const CheckConnection: React.FC = () => {
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const [connection, setConnection] = useState<ConnectionState | null>(
    getInitialState
  );

  const updateConnectionState = useCallback((state: ConnectionState) => {
    setConnection(state);
    if (typeof window !== "undefined") {
      saveStateToLocalStorage(state);
    }
  }, []);

  const checkInternetConnection = useCallback(() => {
    const isOffline = !navigator.onLine;
    const isSlowConnection = navigator?.connection?.downlink < 1;
    const lastCheckedRecently =
      Date.now() - (connection?.lastChecked ?? 0) < VALIDATION_INTERVAL;

    if (isOffline && connection?.title !== "No internet connection.") {
      updateConnectionState({
        title: "No internet connection.",
        message:
          "We apologize for the inconvenience. It seems that your internet connection is currently unavailable. Please check your network settings and try again.",
        speed: (navigator?.connection?.downlink * 1024).toFixed(2),
        lastChecked: Date.now(),
      });
      setOpen(true);
    } else if (isSlowConnection && !lastCheckedRecently) {
      updateConnectionState({
        title: "Slow internet connection detected.",
        message:
          "We've detected that your internet connection is currently experiencing slower than usual speeds. This may result in longer loading times. Please be patient while we retrieve the content.",
        speed: (navigator?.connection?.downlink * 1024).toFixed(2),
        lastChecked: Date.now(),
      });
      setOpen(true);
    }
  }, [connection, updateConnectionState]);

  useEffect(() => {
    const interval = setInterval(checkInternetConnection, 3000);
    return () => clearInterval(interval);
  }, [checkInternetConnection]);

  return (
    // @ts-ignore
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      centered
      style={{ zIndex: 999999 }}
      backdropClassName="max-index-backdrop"
    >
      {/* @ts-ignore */}
      <Modal.Header closeButton>
        {/* @ts-ignore */}
        <Modal.Title>{connection?.title}</Modal.Title>
      </Modal.Header>
      {/* @ts-ignore */}
      <Modal.Body>
        <div className="text-center">
          <p className="text-gray-700">{connection?.message}</p>
        </div>
        <div className="mt-4 text-center">
          Internet Speed: {connection?.speed} kb/s
        </div>
      </Modal.Body>
      {/* @ts-ignore */}
      <Modal.Footer>
        {connection?.title !== "Slow internet connection detected." ? (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => router.reload()}
          >
            Retry
          </button>
        ) : null}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckConnection;
