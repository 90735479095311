import { ImageType } from "@/models";
import Image from "next/image";

export const ImageComponent = ({
  src,
  alt,
  height,
  width,
  className,
}: ImageType) => {
  return (
    <Image
      src={src}
      alt={alt}
      width={width ?? 20}
      height={height ?? 20}
      blurDataURL={src}
      placeholder="blur"
      className={className ?? " "}
    />
  );
};
