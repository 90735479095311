import React from "react";

const ButtonLoader = () => {
  return (
    <span
      className="spinner-border spinner-border-sm text-white spinner-small"
      aria-hidden="true"
    />
  );
};

export default ButtonLoader;
