import React, { useState } from "react";
import NavItemsComponent from "./NavItems";
import NavDropdownComponent from "./NavDropdown";
import LoadingBar from "./LoadingBar";
import LetterImage from "./LetterImageGenerator";
import { ImageComponent } from "./ImageComponent";
import { useRouter } from "next/router";
import { LinkToAnotherPageWithClassName } from "./Utility";
import { BRAND_IMAGE, BRAND_NAME } from "@/constants";
import { useSession } from "next-auth/react";
import { NavItemsType } from "@/models";
import usePageTitle from "@/utils/hooks/usePageTitle";

const NAV_ITEMS = [
  {
    name: "Dashboard",
    url: "/dashboard/performance/district-summary-performance?duration=this month&status=completed",
    icon: "https://designs.hubtel.com/v4/ecg/assets/images/dashboard-icon.svg",
    hasDropdown: true,
    dropdownItems: [
      {
        name: "District Summary Performance",
        url: "/dashboard/performance/district-summary-performance?duration=this month&status=completed",
      },
      {
        name: "District Staff Performance",
        url: "/dashboard/performance/district-staff-performance?duration=this month",
      },
      {
        name: "All Tasks & Service Request",
        url: "/dashboard/performance/all-tasks?duration=this month",
      },
    ],
  },
  {
    name: "Sales Performance",
    url: "/performance/task-performance?duration=last seven days&weeklySummaryFilter=all",
    icon: "https://designs.hubtel.com/v4/ecg/assets/images/sales-performance.svg",
    hasDropdown: true,
    dropdownItems: [
      {
        name: "District Sales Summary",
        url: "/performance/task-performance?duration=this month",
      },
    ],
  },
  {
    name: "Customers",
    url: "/users?page=1",
    icon: "https://designs.hubtel.com/v4/assets/images/ecg/users.svg",
    hasDropdown: false,
  },
  {
    name: "Transactions",
    icon: "https://designs.hubtel.com/v4/assets/images/ecg/transaction.svg",
    url: "/transactions?page=1",
    hasDropdown: true,
    dropdownItems: [
      {
        name: "All",
        url: "/transactions?page=1",
      },
      {
        name: "Bank Pay",
        url: "/transactions/bank-pay?page=1",
      },
      {
        name: "Batch Payment",
        url: "/transactions/batch-payment?page=1",
      },
      {
        name: "Fraud Bill",
        url: "/transactions/fraud-bill?page=1",
      },
      {
        name: "Invoices",
        url: "/transactions/invoices?page=1",
      },
      {
        name: "Non-Smart (Staff Portal)",
        url: "/transactions/non-smart?page=1",
      },
      {
        name: "Reversal Requests",
        customPageName: "Transactions",
        url: "/transactions/reversal-requests?page=1",
      },
      {
        name: "Smart Card Enquiries",
        customPageName: "Transactions",
        url: "/transactions/meter-inquiry",
      },
      {
        name: "Customer Statement",
        customPageName: "Transactions",
        url: "/transactions/customer-statement?page=1",
      },
    ],
  },
  {
    name: "Transactions With Issues",
    icon: "https://designs.hubtel.com/v4/assets/images/ecg/alert-round.svg",
    url: "/challenged-transactions?page=1",
    hasDropdown: true,
    dropdownItems: [
      {
        name: "Transactions",
        url: "/challenged-transactions?page=1",
      },
      {
        name: "Customer Statement Refunds",
        url: "/challenged-transactions/customer-statement?page=1",
      },
    ],
  },
  {
    name: "Refund Management",
    icon: "https://designs.hubtel.com/v4/ecg/assets/images/refund-management.svg",
    url: `/user-issues?page=1`,
    hasDropdown: true,
    dropdownItems: [
      { name: "All Complaints", url: "/user-issues?page=1" },
      {
        name: "Has Unresolved Past Complaints",
        url: "/user-issues/unresolved-complaints?page=1",
      },
      {
        name: "Not Refunded",
        url: "/user-issues/not-refunded?page=1",
      },
      {
        name: "Transactions",
        url: "/user-issues/transactions?page=1",
      },
    ],
  },
  {
    name: "Partner Vendors",
    icon: "https://designs.hubtel.com/v4/assets/images/ecg/vendor.svg",
    url: "/vendors/oltpv?page=1",
    hasDropdown: true,
    dropdownItems: [
      {
        name: "Banks and Fintechs",
        url: "/vendors/oltpv?page=1",
      },
      {
        name: "In-Store Vendors",
        url: "/vendors?page=1",
      },
      {
        name: "Offline Bonded Cashiers",
        url: "/offline-bonded-cashiers?page=1",
      },
    ],
  },
  {
    name: "Turn Meter ON/OFF",
    icon: "https://designs.hubtel.com/v4/assets/images/ecg/meter.svg",
    url: "/meters"
  },
  {
    name: "Public Notices",
    icon: "https://designs.hubtel.com/v4/assets/images/ecg/bell.svg",
    url: "/notifications/group?page=1",
    hasDropdown: true,
    dropdownItems: [
      {
        name: "Group Notifications",
        url: "/notifications/group?page=1",
      },
      {
        name: "User Notifications",
        url: "/notifications/users?page=1",
      },
      {
        name: "Invoice Notifications",
        url: "/invoices?page=1",
      },
      {
        name: "Staff Education Notifications",
        url: "/notifications/staff-education?page=1",
      },
    ],
  },
];

const SUB_NAV_ITEMS = [
  {
    name: "Manage Staff Portal Access",
    url: "/hr-permissions",
    hasDropdown: true,
    dropdownItems: [
      {
        name: "HR Permissions",
        url: "/hr-permissions?page=1",
      },
      {
        name: "Staff Portal Access",
        url: "/staff-portal-access?page=1",
      },
    ],
  },
  {
    name: "Manage Backoffice Access",
    url: "/admin-users",
  },
  {
    name: "Data Bundle Disbursal",
    url: "/performance/data-bundle-disbursal?duration=this month",
  },
  {
    name: "Sign out",
    url: "/api/auth/signout",
  },
];

const LayoutComponent = ({ children }: { children: React.ReactNode }) => {
  const { data: session } = useSession();
  const router = useRouter();
  const currentPage = router.pathname;
  const [open, setOpen] = useState(false);
  const { pageTitle } = usePageTitle();

  let matchedName = "";
  let dropDownItemName = "";
  let dropdownItems: NavItemsType = [];
  let isRootPage = false;

  const userRole = session?.token?.tokenData.role;

  const filteredNavItems = SUB_NAV_ITEMS.filter(
    (item) =>
      item.name !== "Manage Backoffice Access" || userRole === "administrator" || userRole === "qa"
  );

  const getAuthorizedPages = () => {
    const isAdmin = session?.token?.tokenData?.role === "administrator";
    const isNationalSecurity =
      session?.token?.tokenData?.role === "national-security";
    const isNotifications = session?.token?.tokenData?.role === "notifications";

    return NAV_ITEMS.filter((item) => {
      if (isAdmin) {
        return true;
      }

      if (item.name === "Public Notices" && isNotifications) {
        item.dropdownItems = item.dropdownItems?.filter(
          (subItem) =>
            subItem.name !== "Invoice Notifications" &&
            subItem.name !== "Staff Education Notifications"
        );
        return true;
      }

      if (
        item.name === "Public Notices" &&
        (!isNationalSecurity || !isNotifications)
      ) {
        return false;
      }

      if (!isNotifications) {
        return true;
      }
    });
  };

  NAV_ITEMS.forEach((item) => {
    if (item.url.split("?")[0] === currentPage) {
      isRootPage = true;
      matchedName = item.name;
      if (item.hasDropdown) {
        dropdownItems = item.dropdownItems!;
      }
    } else if (item.hasDropdown) {
      item.dropdownItems?.forEach(
        (dropdownItem: {
          name: string;
          url: string;
          customPageName?: string;
        }) => {
          if (dropdownItem.url.split("?")[0] === currentPage) {
            matchedName = item.name;
            isRootPage = false;
            dropDownItemName = dropdownItem.customPageName ?? dropdownItem.name;
            dropdownItems = item.dropdownItems;
          }
        }
      );
    }
  });

  SUB_NAV_ITEMS.forEach((item) => {
    if (item.url.split("?")[0] === currentPage) {
      isRootPage = true;
      matchedName = item.name;
      if (item.hasDropdown) {
        dropdownItems = item.dropdownItems!;
      }
    } else if (item.hasDropdown) {
      item.dropdownItems?.forEach(
        (dropdownItem: {
          name: string;
          url: string;
          customPageName?: string;
        }) => {
          if (dropdownItem.url.split("?")[0] === currentPage) {
            matchedName = item.name;
            isRootPage = false;
            dropDownItemName = dropdownItem.customPageName ?? dropdownItem.name;
            dropdownItems = item.dropdownItems;
          }
        }
      );
    }
  });

  return (
    <>
      {/* <!-- Sidebar --> */}
      {
        <>
          {open && (
            <div
              className={`modal-backdrop fade d-lg-none ${
                open ? "d-block show" : ""
              }`}
              onPointerDown={() => setOpen((prev) => !prev)}
            />
          )}
          <div
            className={`nav-scroller ${open ? "sidebar--small" : ""}`}
            id="navbarAsideContent"
            style={{ background: "#2E3192" }}
            onPointerDown={() => setOpen(false)}
          >
            <div className="sidebar__wrapper sidebar__wrapper--desktop d-flex flex-column h-100">
              <div className="sidebar_icon px-2">
                <li className="home-item overflow-hidden list-unstyled border-bottom">
                  <LinkToAnotherPageWithClassName
                    className="nav-link d-flex flex-column justify-content-center align-items-center gap-2"
                    href="/"
                  >
                    <span
                      className=""
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <ImageComponent
                        src={BRAND_IMAGE}
                        alt="icon"
                        height={70}
                        width={70}
                      />
                    </span>
                    <div className="nav-link-title">{BRAND_NAME}</div>
                  </LinkToAnotherPageWithClassName>
                </li>
              </div>
              <div className="sidebar__content pt-sm-3 d-flex flex-grow-1 h-100 mb-3 overflow-scrollY hide-scroll">
                <div className="nav-scroller pb-3" style={{ width: "100%" }}>
                  <nav className="nav navbar d-flex justify-content-between">
                    <ul className="navbar-nav navbar-aside sidebar-nav">
                      {getAuthorizedPages().map((item) => {
                        const isActive =
                          matchedName === item?.name ||
                          currentPage.split("/")[1] ===
                            item.url.split("?")[0].split("/")[1];
                        return (
                          <NavItemsComponent
                            key={item.name}
                            item={item}
                            name={matchedName}
                            isActive={isActive}
                          />
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="px-2">
                <div className="border-top py-4">
                  <nav className="">
                    <ul className="navbar-nav navbar-aside sidebar-nav">
                      <NavItemsComponent
                        item={{
                          name: "UCS Activity Log",
                          url: "/ucs-events?page=1",
                          icon: "https://designs.hubtel.com/v4/ecg/assets/images/activity-log.svg",
                          hasDropdown: false,
                        }}
                        name={matchedName}
                        isActive={currentPage.split("/")[1] === "ucs-events"}
                      />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {/* <!-- Sidebar --> */}

      <div className="container-wrap">
        <div
          className="position-sticky bg-white top-0"
          style={{ zIndex: 1010 }}
        >
          <nav className="navbar px-3 navbar-expand-lg justify-content-start border-0 navbar-main align-items-center shadow-sm">
            <button
              className="me-0 me-md-3 d-lg-none border-0 bg-transparent"
              aria-expanded="false"
              aria-label="Toggle Sidebar"
              onClick={() => setOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32.395"
                className="topbar__button-icon mt-0"
                height="29.048"
                viewBox="0 0 32.395 29.048"
              >
                <g id="Untitled-1" transform="translate(0.006 -1.871)">
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M.5,1.993a2.413,2.413,0,0,1,.764-.122,2.529,2.529,0,0,1,.263.014H31.309l.069,0a1.129,1.129,0,0,1,.843.377h0a1,1,0,0,1,.167.553.966.966,0,0,1-.013.162V5.749a.95.95,0,0,1,.012.156.994.994,0,0,1-.15.527l0,0a1.129,1.129,0,0,1-.845.38c-.03,0-.06,0-.089,0H1.519a2.483,2.483,0,0,1-.253.012A2.6,2.6,0,0,1,.5,6.7l.018.005A.825.825,0,0,1,0,6.133V2.594a.84.84,0,0,1,.5-.6l.006,0Z"
                  />
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M.529,14.035a2.524,2.524,0,0,1,.993-.1H31.307c.58-.026,1.075.365,1.056.781v3.145a.686.686,0,0,1,.014.144.88.88,0,0,1-.248.582l.01-.012a1.2,1.2,0,0,1-.907.287H1.007a.982.982,0,0,1-1.013-.674V14.646c.012-.234.21-.447.535-.616l0,0Z"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M0,26.692a.966.966,0,0,1,.931-.715c.027,0,.055,0,.081,0h30.3l.064,0a1.136,1.136,0,0,1,.809.337h0a.749.749,0,0,1,.19.5c0,.019,0,.039,0,.058v3.285c.013.414-.5.781-1.065.753H1.074a1.022,1.022,0,0,1-.128.008A1,1,0,0,1,0,30.244l0-.007Z"
                  />
                </g>
              </svg>
            </button>
            <div className="navbar-brand me-auto align-items-center ms-0 d-flex w-auto">
              <div className="ms-2">
                {pageTitle ? (
                  <h5 className="mb-0 fw-bold">{pageTitle}</h5>
                ) : (
                  <h5 className="mb-0 fw-bold">
                    {isRootPage ? matchedName : dropDownItemName}
                  </h5>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div className="dropdown">
                <button
                  className="btn login-btn d-flex align-items-center dropdown-toggle pe-3"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <LetterImage name={session?.user?.name ?? "User"} />

                  <span className="d-none ms-2 d-md-block text-capitalize">
                    {session?.user?.name}
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end p-3"
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "300px" }}
                >
                  <div className="dropdown-header bg-light px-4 py-2 border-radius-8 mb-2">
                    <p className="text-truncate text-black d-flex flex-column mb-0">
                      <span>{session?.user?.name}</span>
                    </p>
                    <p className="text-muted mb-0">{session?.user?.email}</p>
                  </div>
                  <div>
                    <div className="bg-light rounded overflow-hidden">
                      {filteredNavItems.map((item, index) => (
                        <LinkToAnotherPageWithClassName
                          href={item.url}
                          className={`dropdown-item px-4 py-2 mt-2 ${
                            item.name === "Sign out" ? "bg-white" : ""
                          } ${index === 0 ? "rounded-top" : ""} ${
                            index === SUB_NAV_ITEMS.length - 1
                              ? "rounded-bottom"
                              : ""
                          } ${
                            index < SUB_NAV_ITEMS.length - 2
                              ? "short-border-bottom"
                              : "no-border-bottom"
                          }`}
                          key={item.name}
                        >
                          {item.name === "Sign out" ? (
                            <div className="d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                              </svg>
                              <span className="ms-2">{item.name}</span>
                            </div>
                          ) : (
                            item.name
                          )}
                        </LinkToAnotherPageWithClassName>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <nav className="bg-white">
            <LoadingBar />
            <div
              className="nav nav-tabs text-muted"
              id="nav-tab"
              role="tablist"
            >
              {dropdownItems?.map((item) => (
                <NavDropdownComponent key={item.name} item={item} />
              ))}
            </div>
          </nav>
        </div>
        <div className="tab-content" id="nav-tabContent">
          <section className="p-2">
            <div>{children}</div>
          </section>
        </div>
      </div>
    </>
  );
};

export default LayoutComponent;
